import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { loadQuery } from 'react-relay';
import Welcome_Query from 'Pages/Welcome/__generated__/Welcome_Query.graphql';
import MultiServiceLocations_Query from 'Pages/MultiServiceLocations/__generated__/MultiServiceLocations_Query.graphql';
import LocationInformation_Query from 'Pages/LocationInformation/__generated__/LocationInformation_Query.graphql';
import OfficeInformation_Query from 'Pages/OfficeInformation/__generated__/OfficeInformation_Query.graphql';
import Practitioner_Query from 'Pages/Practitioner/__generated__/Practitioner_Query.graphql';
import Submit_Query from 'Pages/Submit/__generated__/Submit_Query.graphql';
import RelayEnvironment from './relayEnvironment';
import lazyWithRetry from './Utils/lazyWithRetry';
import RouterErrorBoundary from './Components/Atoms/RouterErrorBoundary/RouterErrorBoundary';

const LazyLanding = lazyWithRetry(() => import('Pages/Landing/Landing'));
const LazyLayout = lazyWithRetry(() => import('Components/Atoms/Layout/Layout'));
const LazyLocationInformation = lazyWithRetry(
  () => import('Pages/LocationInformation/LocationInformation')
);
const LazyMaintenance = lazyWithRetry(() => import('Pages/Maintenance/Maintenance'));
const LazyMultiServiceLocations = lazyWithRetry(
  () => import('Pages/MultiServiceLocations/MultiServiceLocations')
);
const LazyOfficeInformation = lazyWithRetry(
  () => import('Pages/OfficeInformation/OfficeInformation')
);
const LazyPractitioner = lazyWithRetry(() => import('Pages/Practitioner/Practitioner'));
const LazySubmit = lazyWithRetry(() => import('Pages/Submit/Submit'));
const LazyWelcome = lazyWithRetry(() => import('Pages/Welcome/Welcome'));
const LazySubmitSuccess = lazyWithRetry(() => import('Pages/SubmitSuccess/SubmitSuccess'));

const router = createBrowserRouter([
  ['1', 'true'].includes(window._env_.REACT_APP_UNDER_MAINTENANCE?.toLocaleLowerCase() ?? 'false')
    ? {
        path: '*',
        element: <LazyLayout />,
        errorElement: <RouterErrorBoundary />,
        children: [
          {
            path: '*',
            element: <LazyMaintenance />
          }
        ]
      }
    : {
        path: '/',
        element: <LazyLayout />,
        errorElement: <RouterErrorBoundary />,
        children: [
          {
            path: '/outreach/:shortcode/service-locations',
            loader: ({ params }) =>
              loadQuery(
                RelayEnvironment,
                MultiServiceLocations_Query,
                {
                  shortcode: params.shortcode
                },
                { fetchPolicy: 'store-and-network' }
              ),
            element: <LazyMultiServiceLocations />
          },
          {
            path: '/outreach/:shortcode/session/:sessionId/location-information',
            loader: ({ params }) =>
              loadQuery(RelayEnvironment, LocationInformation_Query, {
                shortcode: params.shortcode,
                sessionId: params.sessionId
              }),
            element: <LazyLocationInformation />
          },
          {
            path: '/outreach/:shortcode/session/:sessionId/office',
            loader: ({ params }) =>
              loadQuery(RelayEnvironment, OfficeInformation_Query, {
                shortcode: params.shortcode,
                sessionId: params.sessionId
              }),
            element: <LazyOfficeInformation />
          },
          {
            path: '/outreach/:shortcode/session/:sessionId/practitioner-at-location/:practitionerToLocationId',
            loader: ({ params }) =>
              loadQuery(RelayEnvironment, Practitioner_Query, {
                shortcode: params.shortcode,
                sessionId: params.sessionId,
                practitionerToLocationId: params.practitionerToLocationId
              }),
            element: <LazyPractitioner />
          },
          {
            path: '/outreach/:shortcode/session/:sessionId/submit',
            loader: ({ params }) =>
              loadQuery(RelayEnvironment, Submit_Query, {
                shortcode: params.shortcode,
                sessionId: params.sessionId
              }),
            element: <LazySubmit />
          },
          {
            path: '/outreach/:shortcode/session/:sessionId/submit-not-my-location',
            loader: ({ params }) =>
              loadQuery(RelayEnvironment, Submit_Query, {
                shortcode: params.shortcode,
                sessionId: params.sessionId
              }),
            element: <LazySubmit notMyLocation={true} />
          },
          {
            path: '/outreach/:shortcode',
            loader: ({ params }) =>
              loadQuery(RelayEnvironment, Welcome_Query, {
                shortcode: params.shortcode
              }),
            element: <LazyWelcome />
          },
          {
            path: '/submit-success',
            element: <LazySubmitSuccess />
          },
          {
            path: '/',
            element: <LazyLanding />
          },
          {
            path: '/:shortcode',
            element: <LazyLanding />
          },
          {
            path: '*',
            element: <div>Sorry, we don't have any routes, 404!!!</div>
          }
        ]
      }
]);

const Router = () => {
  return <RouterProvider router={router} />;
};

export default Router;
