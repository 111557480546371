/**
 * @generated SignedSource<<25c5a27f11b5b0e0c015a9fe2bfbcfac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OutreachMethod = "DIRECT_MAIL" | "EMAIL" | "FAX" | "PHONE" | "%future added value";
export type MultiServiceLocations_Query$variables = {
  after?: string | null;
  first?: number | null;
  shortcode: string;
};
export type MultiServiceLocations_Query$data = {
  readonly outreachRequest: {
    readonly isLghsRecruit: boolean;
    readonly shortCode: string | null;
    readonly targetContact: {
      readonly emailAddress: string | null;
      readonly faxNumber: string | null;
      readonly outreachMethod: OutreachMethod;
      readonly phoneNumber: string | null;
    } | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"MultiServiceLocationTable_targetContacts">;
};
export type MultiServiceLocations_Query = {
  response: MultiServiceLocations_Query$data;
  variables: MultiServiceLocations_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "shortcode"
},
v3 = [
  {
    "kind": "Variable",
    "name": "shortcode",
    "variableName": "shortcode"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shortCode",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isLghsRecruit",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "outreachMethod",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "emailAddress",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "faxNumber",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phoneNumber",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v11 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  }
],
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "MultiServiceLocations_Query",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "OutreachRequest",
        "kind": "LinkedField",
        "name": "outreachRequest",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "TargetContact",
            "kind": "LinkedField",
            "name": "targetContact",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "MultiServiceLocationTable_targetContacts"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "MultiServiceLocations_Query",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "OutreachRequest",
        "kind": "LinkedField",
        "name": "outreachRequest",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "TargetContact",
            "kind": "LinkedField",
            "name": "targetContact",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/)
            ],
            "storageKey": null
          },
          (v10/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "showInterpreterTest",
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v11/*: any*/),
            "concreteType": "DynamicServiceLocationsConnectionConnection",
            "kind": "LinkedField",
            "name": "dynamicServiceLocationsConnection",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "DynamicServiceLocationsConnectionEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DynamicServiceLocation",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "xid",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lastAttestedDate",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DynamicAddress",
                        "kind": "LinkedField",
                        "name": "address",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "address1",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "address2",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "city",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "state",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "zip",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "healthcarePractitionerXids",
                        "storageKey": null
                      },
                      (v12/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v11/*: any*/),
            "filters": null,
            "handle": "connection",
            "key": "MultiServiceLocationTable_dynamicServiceLocationsConnection",
            "kind": "LinkedHandle",
            "name": "dynamicServiceLocationsConnection"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "sessions",
            "plural": true,
            "selections": [
              (v12/*: any*/),
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isNotMyLocation",
                "storageKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ServiceLocationData",
                    "kind": "LinkedField",
                    "name": "serviceLocation",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "referenceServiceLocationXid",
                        "storageKey": null
                      },
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "type": "ServiceLocationSession",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "FeatureFlagService",
        "kind": "LinkedField",
        "name": "featureFlags",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isTelehealthWaitTimesEnabled",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isInterpreterTestEnabled",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "DomainValueService",
        "kind": "LinkedField",
        "name": "domainValues",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DomainListReadModel",
            "kind": "LinkedField",
            "name": "attesterRoles",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "value",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "label",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "162edef32f0cc816242545c799ebd131",
    "id": null,
    "metadata": {},
    "name": "MultiServiceLocations_Query",
    "operationKind": "query",
    "text": "query MultiServiceLocations_Query(\n  $shortcode: String!\n  $first: Int\n  $after: String\n) {\n  outreachRequest(shortcode: $shortcode) {\n    shortCode\n    isLghsRecruit\n    targetContact {\n      outreachMethod\n      emailAddress\n      faxNumber\n      phoneNumber\n      id\n    }\n    id\n  }\n  ...MultiServiceLocationTable_targetContacts\n}\n\nfragment MultiServiceLocationTable_targetContacts on Query {\n  outreachRequest(shortcode: $shortcode) {\n    shortCode\n    outreachMethod\n    showInterpreterTest\n    dynamicServiceLocationsConnection(first: $first, after: $after) {\n      totalCount\n      edges {\n        cursor\n        node {\n          xid\n          name\n          lastAttestedDate\n          address {\n            address1\n            address2\n            city\n            state\n            zip\n          }\n          healthcarePractitionerXids\n          __typename\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n    sessions {\n      __typename\n      id\n      isNotMyLocation\n      ... on ServiceLocationSession {\n        serviceLocation {\n          referenceServiceLocationXid\n          id\n        }\n      }\n    }\n    id\n  }\n  featureFlags {\n    isTelehealthWaitTimesEnabled\n    isInterpreterTestEnabled\n  }\n  domainValues {\n    attesterRoles {\n      value\n      label\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3ff192a782abc91e4f0238f6a01103a6";

export default node;
