const formErrorReducer = (state: string[], action: { name: string; type: 'add' | 'remove' }) => {
  switch (action.type) {
    case 'add':
      return !state.some((name) => action.name === name) ? [...state, action.name] : state;
    case 'remove':
      return state.some((name) => action.name === name)
        ? state.filter((name) => action.name !== name)
        : state;
    default:
      throw new Error('Error in FormErrorReducer');
  }
};

export default formErrorReducer;
