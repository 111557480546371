/**
 * @generated SignedSource<<b378e0437b58ef72b1f0bacf9c5ecf3c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Welcome_Query$variables = {
  after?: string | null;
  first?: number | null;
  shortcode: string;
};
export type Welcome_Query$data = {
  readonly featureFlags: {
    readonly isInterpreterTestEnabled: boolean;
    readonly isTelehealthWaitTimesEnabled: boolean;
  };
  readonly outreachRequest: {
    readonly dynamicServiceLocationsConnection: {
      readonly edges: ReadonlyArray<{
        readonly cursor: string;
        readonly node: {
          readonly healthcarePractitionerXids: ReadonlyArray<string | null>;
          readonly xid: string;
        };
      }> | null;
      readonly totalCount: number;
    } | null;
    readonly isMultiServiceLocation: boolean;
    readonly sessions: ReadonlyArray<{
      readonly id: string;
    }>;
    readonly shortCode: string | null;
    readonly showInterpreterTest: boolean;
  } | null;
};
export type Welcome_Query = {
  response: Welcome_Query$data;
  variables: Welcome_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "shortcode"
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "FeatureFlagService",
  "kind": "LinkedField",
  "name": "featureFlags",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isTelehealthWaitTimesEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isInterpreterTestEnabled",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "shortcode",
    "variableName": "shortcode"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shortCode",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isMultiServiceLocation",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "showInterpreterTest",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v9 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "DynamicServiceLocationsConnectionEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "DynamicServiceLocation",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "xid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "healthcarePractitionerXids",
            "storageKey": null
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "PageInfo",
    "kind": "LinkedField",
    "name": "pageInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endCursor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasNextPage",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v11 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "Welcome_Query",
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "OutreachRequest",
        "kind": "LinkedField",
        "name": "outreachRequest",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": "dynamicServiceLocationsConnection",
            "args": null,
            "concreteType": "DynamicServiceLocationsConnectionConnection",
            "kind": "LinkedField",
            "name": "__Welcome_dynamicServiceLocationsConnection_connection",
            "plural": false,
            "selections": (v9/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "sessions",
            "plural": true,
            "selections": [
              (v10/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "Welcome_Query",
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "OutreachRequest",
        "kind": "LinkedField",
        "name": "outreachRequest",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": (v11/*: any*/),
            "concreteType": "DynamicServiceLocationsConnectionConnection",
            "kind": "LinkedField",
            "name": "dynamicServiceLocationsConnection",
            "plural": false,
            "selections": (v9/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v11/*: any*/),
            "filters": null,
            "handle": "connection",
            "key": "Welcome_dynamicServiceLocationsConnection",
            "kind": "LinkedHandle",
            "name": "dynamicServiceLocationsConnection"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "sessions",
            "plural": true,
            "selections": [
              (v8/*: any*/),
              (v10/*: any*/)
            ],
            "storageKey": null
          },
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "efa6f3f79b646db8ab2ff6428d7e46ed",
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": "first",
          "cursor": "after",
          "direction": "forward",
          "path": [
            "outreachRequest",
            "dynamicServiceLocationsConnection"
          ]
        }
      ]
    },
    "name": "Welcome_Query",
    "operationKind": "query",
    "text": "query Welcome_Query(\n  $shortcode: String!\n  $first: Int\n  $after: String\n) {\n  featureFlags {\n    isTelehealthWaitTimesEnabled\n    isInterpreterTestEnabled\n  }\n  outreachRequest(shortcode: $shortcode) {\n    shortCode\n    isMultiServiceLocation\n    showInterpreterTest\n    dynamicServiceLocationsConnection(first: $first, after: $after) {\n      totalCount\n      edges {\n        cursor\n        node {\n          xid\n          healthcarePractitionerXids\n          __typename\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n    sessions {\n      __typename\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "e126939f1c859afdd1e714491324648a";

export default node;
