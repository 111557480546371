import { createTheme, responsiveFontSizes } from '@mui/material/styles';

declare module '@mui/material/styles/createTheme' {
  interface Theme {
    sizes: {
      mobileFormOffset: {
        offset: Number;
      };
      landingNav: {
        large: React.CSSProperties['height'];
        small: React.CSSProperties['height'];
      };
      sideNav: {
        large: React.CSSProperties['height'];
        small: React.CSSProperties['height'];
      };
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    sizes?: {
      mobileFormOffset?: {
        offset?: Number;
      };
      landingNav?: {
        large?: React.CSSProperties['height'];
        small?: React.CSSProperties['height'];
      };
      sideNav?: {
        large?: React.CSSProperties['height'];
        small?: React.CSSProperties['height'];
      };
    };
  }
}

const customTheme = {
  spacing: 4,
  sizes: {
    mobileFormOffset: {
      offset: 130
    },
    landingNav: {
      large: 64,
      small: 56
    },
    sideNav: {
      small: 240,
      large: 360
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      mds: 768,
      md: 960,
      lg: 1280,
      xl: 1920
    }
  },
  palette: {
    contrastThreshold: 3, // Must maintain minimum of 3 for accessibility
    tonalOffset: 0.2,
    primary: {
      main: '#32323C', // Header background
      contrastText: '#fff'
    },
    secondary: {
      main: '#6E6E8B', // Footer background
      contrastText: '#fff'
    },
    error: {
      light: '#DA334E',
      main: '#CC3A52'
    },
    warning: {
      light: '#E6914C',
      main: '#B65404',
      dark: '#EF5241'
    },
    success: {
      light: '#00B979',
      main: '#008558'
    },
    info: {
      light: '#F6FAFF',
      main: '#1671dc'
    },
    background: {
      // main: '#FFFFFF',
      lightGrey: '#F2F2FC',
      accentGrey: '#F9F9FF',
      default: '#FBFBFF'
    },
    text: {
      // white: '#fff',
      primary: '#32323C', // Used for header and body text
      secondary: '#6E6E8B',
      links: '#1671dc',
      disabled: 'rgba(0, 0, 0, 0.38)',
      accent: '#ACACC5'
    },
    action: {
      active: '#4FA0FF',
      hover: 'rgba(0, 0, 0, 0.08)',
      hoverOpacity: 0.75,
      selected: 'rgba(0, 0, 0, 0.14)',
      disabled: 'rgba(0, 0, 0, 0.26)',
      disabledBackground: 'rgba(0, 0, 0, 0.12)'
    },
    grey: {
      50: '#E2E2F8',
      100: '#F2F2FC',
      200: '#6E6E8B'
    }
  },
  typography: {
    fontFamily: 'Avenir Next, sans-serif',
    h1: {
      fontSize: '2.375rem',
      lineHeight: 1.25,
      fontWeight: 400
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 500,
      lineHeight: 1.5
    },
    h3: {
      fontSize: '2rem',
      lineHeight: 1.5,
      letterSpacing: '0em'
    },
    h4: {
      fontSize: '1.25rem',
      fontWeight: 600,
      lineHeight: 1
    },
    h5: {
      fontSize: '1rem',
      fontWeight: 600,
      lineHeight: 1.8
    },
    h6: {
      fontSize: '.875rem',
      fontWeight: 600,
      lineHeight: 1
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: 1.8
    },
    body2: {
      fontSize: '.875rem',
      fontWeight: 500
    },
    subtitle1: {
      fontStyle: 'italic',
      color: '#6E6E8B',
      fontSize: '.875rem',
      lineHeight: 1,
      fontWeight: 500
    },
    subtitle2: {
      fontSize: '.75rem',
      lineHeight: 1.4,
      fontWeight: 500
    },
    button: {
      fontSize: '1.125rem'
    }
  },
  // shadows: ['none', ...new Array(24).fill('0 2px 2px rgba(50,50,60,0.1)')]
  components: {
    MuiExpansionPanelSummary: {
      styleOverrides: {
        root: {
          minHeight: 64,
          '&expanded': {
            minHeight: 64
          }
        },
        content: {
          '&$expanded': {
            margin: 'unset'
          }
        },
        expandIcon: {
          '&$expanded': {
            transform: 'none'
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          height: '100%'
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          border: 1,
          borderColor: 'grey.50',
          '&:invalid': {
            color: 'rgba(0, 0, 0, 0.38)'
          }
        }
      }
    },
    MuiNativeSelect: {
      styleOverrides: {
        standard: {
          padding: 0
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            background: 'unset',
            '&:hover': {
              background: 'rgba(0, 0, 0, 0.08)'
            }
          }
        }
      }
    }
  }
};

const theme = responsiveFontSizes(createTheme(customTheme));

export default theme;
