import { Environment, Network, RecordSource, Store } from 'relay-runtime';

function fetchQuery(operation, variables) {
  return fetch(
    `${window._env_.REACT_APP_QOP_API_URI}${
      window._env_.REACT_APP_QOP_API_URI.slice(-1) === '/' ? '' : '/'
    }graphql`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('shortcode')}`
      },
      body: JSON.stringify({
        query: operation.text,
        variables
      })
    }
  ).then(async (response) => {
    var jsonResponse = await response.json();
    jsonResponse.errors?.forEach((e) => {
      throw new Error(e.message);
    });
    return jsonResponse;
  });
}

const RelayEnvironment = new Environment({
  network: Network.create(fetchQuery),
  store: new Store(new RecordSource())
});

export default RelayEnvironment;
