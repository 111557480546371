import { Suspense, useReducer, useState } from 'react';
import { RelayEnvironmentProvider } from 'react-relay';
import { HelmetProvider } from 'react-helmet-async';
import { CircularProgress, CssBaseline, Stack } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import RosterSubmissionCard from 'Components/Molecules/RosterSubmissionCard/RosterSubmissionCard';
import { FormErrorContext } from 'Contexts/Error';
import { ProspectiveLghsNotificationContext } from 'Contexts/ProspectiveLghsNotification';
import { PageAdvanceErrorContext } from 'Contexts/PageAdvance';
import formErrorReducer from 'Utils/formErrorReducer';
import Router from 'Router';
import RelayEnvironment from './relayEnvironment';
import theme from './theme';

const App = () => {
  const [formErrors, setFormErrors] = useReducer(formErrorReducer, []);
  const [rosterSubmitVerificationOpen, setRosterSubmitVerificationOpen] = useState(false);

  return (
    <HelmetProvider>
      <RelayEnvironmentProvider environment={RelayEnvironment}>
        <FormErrorContext.Provider value={setFormErrors}>
          <PageAdvanceErrorContext.Provider value={{ formErrors: formErrors }}>
            <ProspectiveLghsNotificationContext.Provider value={setRosterSubmitVerificationOpen}>
              <ThemeProvider theme={theme}>
                <CssBaseline />
                {rosterSubmitVerificationOpen && (
                  <RosterSubmissionCard setOpen={setRosterSubmitVerificationOpen} />
                )}
                <Suspense
                  fallback={
                    <Stack
                      justifyContent="center"
                      alignItems="center"
                      sx={{ width: '100vw', height: '100vh' }}
                    >
                      <CircularProgress />
                    </Stack>
                  }
                >
                  <Router />
                </Suspense>
              </ThemeProvider>
            </ProspectiveLghsNotificationContext.Provider>
          </PageAdvanceErrorContext.Provider>
        </FormErrorContext.Provider>
      </RelayEnvironmentProvider>
    </HelmetProvider>
  );
};

export default App;
