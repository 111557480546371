import { Box, Button, IconButton, Paper, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Dispatch, SetStateAction } from 'react';
import theme from 'theme';

interface RosterSubmissionCardProps {
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const RosterSubmissionCard = ({ setOpen }: RosterSubmissionCardProps) => {
  return (
    <Box
      sx={{
        position: 'fixed',
        zIndex: 1,
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
        paddingTop: '80px',
        paddingRight: '20px'
      }}
    >
      <Paper
        sx={{
          width: '35%',
          px: '40px',
          py: '20px',
          position: 'relative',
          boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.2)'
        }}
      >
        <IconButton
          sx={{
            position: 'absolute',
            paddingRight: 8,
            paddingTop: 3,
            top: 0,
            right: 0,
            color: theme.palette.grey[600],
            '&:hover': {
              backgroundColor: 'transparent',
              color: theme.palette.grey[400]
            }
          }}
          onClick={() => setOpen(false)}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h4" sx={{ fontWeight: 'normal' }}>
          Start the Roster Submission Process Now
        </Typography>
        <br />
        <Typography variant="body1">
          Enroll to streamline this process and start submitting your rosters as a Large Group or
          Health System.
        </Typography>
        <Stack flexDirection="row" justifyContent="flex-end" sx={{ mt: 5 }}>
          <Button
            size="small"
            sx={{
              backgroundColor: 'white',
              color: theme.palette.info.main,
              mr: 3,
              '&:hover': {
                backgroundColor: theme.palette.info.light
              },
              '&.MuiButton-root': {
                textTransform: 'none',
                fontStyle: 'Normal',
                fontSize: '14px'
              }
            }}
            onClick={() => setOpen(false)}
          >
            No, Continue this Process
          </Button>
          <Button
            variant="contained"
            size="small"
            sx={{
              textTransform: 'unset',
              backgroundColor: theme.palette.info.main,
              borderRadius: 0.8,
              '&:hover': {
                backgroundColor: theme.palette.info.main,
                opacity: theme.palette.action.hoverOpacity
              },
              '&.MuiButton-root': {
                textTransform: 'none',
                fontStyle: 'Normal',
                fontSize: '14px'
              }
            }}
            href={window._env_.REACT_APP_LGHS_ENROLLMENT_URL ?? ''}
            target="_blank"
            onClick={() => setOpen(false)}
          >
            Yes, I Want to Enroll
          </Button>
        </Stack>
      </Paper>
    </Box>
  );
};

export default RosterSubmissionCard;
