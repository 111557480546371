import { lazy } from 'react';

const lazyWithRetry = <T>(componentImport: () => Promise<{ default: React.ComponentType<T> }>) =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem('page-has-been-force-refreshed') || 'false'
    );

    try {
      const component = await componentImport();
      window.localStorage.setItem('page-has-been-force-refreshed', 'false');

      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // if there is a bundle mismatch,
        // refresh the page to get the latest version.
        window.localStorage.setItem('page-has-been-force-refreshed', 'true');
        window.location.reload();
      }

      // rethrow error for error boundary to catch
      throw error;
    }
  });

export default lazyWithRetry;
