import { useCallback } from 'react';
import { InfoOutlined, Cancel } from '@mui/icons-material';
import { Box, Typography, TypographyProps } from '@mui/material';

interface InfoAlertProps {
  interaction?: {
    text: TypographyProps['children'];
    func: VoidFunction;
  };
  error?: boolean;
  children: TypographyProps['children'];
}

const InfoAlert = ({ children, interaction, error = false }: InfoAlertProps) => {
  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      e.keyCode === 13 && interaction?.func();
    },
    [interaction]
  );

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: 5,
        bgcolor: error ? '#FDF5F6' : 'info.light'
      }}
    >
      {error ? (
        <Cancel sx={{ height: '24px', fill: 'error.light' }} />
      ) : (
        <InfoOutlined sx={{ height: '24px', color: 'info.main' }} data-testid="info-icon" />
      )}

      <Typography
        sx={{
          color: 'grey.200',
          marginLeft: 5,
          lineHeight: 1.4,
          flexGrow: 1
        }}
        variant="h6"
        align="left"
      >
        {children}
      </Typography>
      {interaction && (
        <Typography
          variant="h6"
          align="right"
          sx={{
            color: error ? 'error.main' : 'info.main',
            '&:hover': {
              cursor: 'pointer'
            }
          }}
          onClick={interaction.func}
          onKeyDown={handleKeyDown}
          tabIndex={0}
          // type="button"
        >
          {interaction.text}
        </Typography>
      )}
    </Box>
  );
};

export type { InfoAlertProps };
export default InfoAlert;
