import React from 'react';
import { createRoot } from 'react-dom/client';
import * as FullStory from '@fullstory/browser';
import App from './App';

if (process.env.NODE_ENV === 'production') {
  FullStory.init({ orgId: 'G0RAJ' });
}

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
