import { datadogLogs } from '@datadog/browser-logs';
import { useRouteError } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Box, Link } from '@mui/material';
import { InfoAlert } from '..';

if (!datadogLogs.getInitConfiguration()) {
  datadogLogs.init({
    clientToken: window._env_.REACT_APP_DATADOG_CLIENT_TOKEN || '',
    site: 'datadoghq.com',
    forwardErrorsToLogs: false,
    sampleRate: 100
  });
}

const RouterErrorBoundary = () => {
  const error = useRouteError();

  const uuid = uuidv4();
  console.error('Uncaught error:', error, uuid);
  //will not report locally unless deployment environment is manually set
  if (
    ['production', 'development', 'sit'].includes(
      window._env_.REACT_APP_DEPLOYMENT_ENVIRONMENT || ''
    )
  ) {
    datadogLogs.logger.error('Uncaught Error', {
      name: error,
      id: uuid,
      env: window._env_.REACT_APP_DEPLOYMENT_ENVIRONMENT,
      app: 'Portal'
    });
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', height: '100vh' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <InfoAlert error>
          Something went wrong. Our team has been notified and is working to resolve the issue.
          <br />
          In the meanwhile, you may try to{' '}
          <span onClick={() => window.location.reload()} style={{ cursor: 'pointer' }}>
            <u>refresh</u>
          </span>{' '}
          the page. If the problem persists, please{' '}
          <Link
            underline="always"
            color="inherit"
            href="https://questanalytics.com/how-we-help/betterdoctor/betterdoctor-faq/"
            target="_blank"
          >
            contact our support.
          </Link>
        </InfoAlert>
      </Box>
    </Box>
  );
};

export default RouterErrorBoundary;
